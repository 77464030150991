import { Link } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import "../styles/slider.css"

const Slider = ({ sliderArray }) => {
  const [translation, setTranslation] = useState(0)
  const sliderLength = sliderArray.length - 1

  const nextSlide = () => {
    setTranslation(translation === sliderLength * 100 ? 0 : translation + 100)
  }

  const prevSlide = () => {
    setTranslation(translation === 0 ? sliderLength * 100 : translation - 100)
  }

  const autoPlayRef = useRef()

  useEffect(() => {
    autoPlayRef.current = nextSlide
  })

  useEffect(() => {
    const play = () => {
      autoPlayRef.current()
    }

    const interval = setInterval(play, 4000)
    return () => clearInterval(interval)
  }, [])

  return (
    <section className="slider-container">
      <div
        className="slider"
        style={{ transform: `translateX(-${translation}%)` }}
      >
        {sliderArray.map((currentSlide, index) => {
          return (
            <div className="slider__section" key={index}>
              <Link to="/menu">
                <picture>
                  <source
                    srcSet={currentSlide.imageDesktopSrc}
                    media="(min-width: 500px)"
                  />
                  <img
                    src={currentSlide.imageSrc}
                    alt={currentSlide.imageDescription}
                  />
                </picture>
              </Link>
            </div>
          )
        })}
      </div>
      <div
        tabIndex="0"
        role="button"
        className="slider-button right"
        onClick={nextSlide}
      >
        {">"}
      </div>
      <div
        tabIndex="0"
        role="button"
        className="slider-button left"
        onClick={prevSlide}
      >
        {"<"}
      </div>
    </section>
  )
}

export default Slider
