import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import sliderData from "../utils/sliderData"
import "../styles/home.css"
import Head from "../components/Head"

export default function Home() {
  return (
    <>
      <Head
        description="En asadero y restaurante roque fer ven y prueba el mejor pollo asado de Bogotá desde 1995, visítamos o pide a domicilio desde la comodidad de tu casa. conoce nuestro variado menú y antójate"
        title="Asadero Roque Fer"
        keywords="Pollo asado familiar bogota carnes sopas pollo con ensalada arroz papas "
      />
      <Layout>
        <main>
          <Slider sliderArray={sliderData} />
          <section className="info-container">
            <Link to="/menu">
              <article className="info-menu">
                <p>CONOCE EL MENÚ</p>
              </article>
            </Link>
            <Link to="/pedidos">
              <article className="info-shippings">
                <p>DOMICILIOS</p>
              </article>
            </Link>
            <Link className="home-restaurants" to="/restaurantes">
              <article className="info-restaurants">
                <p>NUESTROS RESTAURANTES</p>
              </article>
            </Link>
          </section>
        </main>
      </Layout>
    </>
  )
}
