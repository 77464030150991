import polloDesktop from "../../static/assets/images/slider/UN_POLLO.jpg"
import pollo from "../../static/assets/images/menu/pollos/unpollo.jpg"
import carneDesktop from "../../static/assets/images/slider/SOBREBARRIGA.jpg"
import carne from "../../static/assets/images/menu/carnes/unasobrebarriga.jpg"
import mojarraDesktop from "../../static/assets/images/slider/MOJARRA.jpg"
import mojarra from "../../static/assets/images/menu/especiales/MOJARRA.jpg"
import ajiacoDesktop from "../../static/assets/images/slider/AJIACO_CON_POLLO.jpg"
import ajiaco from "../../static/assets/images/menu/sopas/sopaajiacoconpollo.jpg"
import cuartoPolloDesktop from "../../static/assets/images/slider/CUARTO_POLLO_SOLO_PAPA.jpg"
import cuartoPollo from "../../static/assets/images/menu/pollos/uncuartopollosolopapa.jpg"

const sliderData = [
  {
    imageSrc: pollo,
    imageDesktopSrc: polloDesktop,
    imageDescription: "Pollo entero asado con arroz papa y ensalada",
  },
  {
    imageSrc: carne,
    imageDesktopSrc: carneDesktop,
    imageDescription: "Sobrebarriga asada con arroz papa y ensalada",
  },
  {
    imageSrc: mojarra,
    imageDesktopSrc: mojarraDesktop,
    imageDescription: "Mojarra frita con arroz papa y ensalada",
  },
  {
    imageSrc: ajiaco,
    imageDesktopSrc: ajiacoDesktop,
    imageDescription: "Sopa de ajiaco con pollo desmechado",
  },
  {
    imageSrc: cuartoPollo,
    imageDesktopSrc: cuartoPolloDesktop,
    imageDescription: "Cuarto de pollo asado con papas fritas",
  },
]

export default sliderData
